@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, Verdana, arial, 'HiraKakuProN-W3', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDGothic', Meiryo, sans-serif
}

.pagination li>a {
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.375rem;
}

.pagination li>a:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.pagination .active>a {
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.pagination li.pagination__disabled>a{
  cursor: not-allowed;
}

.pagination li.pagination__disabled>a:hover {
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}